import React, { useEffect, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Clients from '../../components/organisms/Clients'
import { default as CTABase } from '../../components/organisms/CTA'
import CultureAndPurpose from '../../components/organisms/Culture'
import HighLight from '../../components/organisms/HighLight'
import Layout from '../../templates/layout'
import Leaders from '../../components/molecules/Leaders'
import SectionContainer from '../../components/molecules/SectionContainer'
import Seo from '../../helpers/seo'
import Slogan from '../../components/molecules/Slogan'
import Timeline from '../../components/organisms/Timeline'
import useWindowDimensions from '../../hooks/useWindowDimensions'

import './styles.scss'
import { CircledArrow } from '../../components/molecules/Icons'
import useScreenWidth from '../../hooks/useScreenWidth'
import VideoPlayer from '../../components/molecules/VideoPlayer'
import drInstitucional from '../../assets/videos/dr-institucional.mp4'

const Banner = () => {
  const { isMobile } = useScreenWidth()

  return (
    <section className="about-us__banner">
      {isMobile ? (
        <StaticImage
          src="../../assets/images/banner-contact.webp"
          alt="DR_"
          placeholder="blurred"
          loading="eager"
          width={920}
          objectFit="cover"
          layout="fixed"
          objectPosition="6% center"
          quality={95}
          formats={['auto', 'webp', 'avif']}
          class="about-us__banner"
        />
      ) : (
        <StaticImage
          src="../../assets/images/banner-contact.webp"
          alt="DR_"
          placeholder="blurred"
          loading="eager"
          width={1920}
          objectFit="cover"
          layout="fixed"
          objectPosition="6% center"
          quality={95}
          formats={['auto', 'webp', 'avif']}
        />
      )}
    </section>
  )
}

const CTA = () => {
  return (
    <div className="about-us__CTA-wrapper">
      <CTABase />
    </div>
  )
}

const GetToKnowUs = () => {
  const [windowWidth] = useWindowDimensions()
  const [shouldShow, setShouldShow] = useState(true)

  useEffect(() => {
    if (windowWidth < 768) {
      setShouldShow(false)
    }
  }, [])

  function handleShow() {
    setShouldShow(true)
  }

  return (
    <SectionContainer>
      <article className="get-to-know-us">
        <p className="get-to-know-us__paragraph">
          Assim como o ser humano, cada empresa possui um DNA: uma história, um objetivo,
          um diferencial. Por isso,{' '}
          <b>
            nos dedicamos a construir soluções digitais personalizadas para atender a uma
            necessidade que é singular e sem barreiras geográficas.
          </b>{' '}
          Afinal, já atuamos em projetos nos EUA, Londres, Bélgica, Dinamarca, Uruguai e,
          claro, no Brasil.
          <br />
          <br />
          Colocar em prática diferenciais como qualidade, foco no cliente, simplicidade e
          inovação só é possível porque temos uma base sólida, composta por um time
          multidisciplinar de especialistas que se desenvolvem profissionalmente aqui, na
          DR_, para entregar todo o conhecimento em forma de soluções.
        </p>
        {(windowWidth > 768 || shouldShow) && (
          <>
            <p className="get-to-know-us__paragraph">
              Somos de São Paulo, e estamos há quase uma década proporcionando projetos
              sólidos e de alto valor, com foco no que há de moderno em boas práticas e
              tecnologia. Também priorizamos o sucesso de nossos clientes,{' '}
              <b>
                procurando antecipar suas necessidades e entregar atendimento e soluções
                personalizados.
              </b>
              <br />
              <br />
              Juntando todos os elementos acima, o resultado não poderia ser diferente:{' '}
              <b>
                projetos de excelência, iniciativas premiadas internacionalmente e
                clientes que se tornam grandes parceiros.
              </b>{' '}
              Assim, seguimos oferecendo uma experiência única de geração de resultados.
            </p>
          </>
        )}
      </article>
      {!shouldShow && (
        <input
          className="get-to-know-us__expand-btn"
          onClick={handleShow}
          type="checkbox"
        />
      )}

      <a href="/services" className="get-to-know-us__anchor">
        {windowWidth < 768 ? (
          <>Saiba como fazemos a diferença</>
        ) : (
          <>Saiba como podemos fazer a diferença no seu negócio</>
        )}
        <CircledArrow width="20px" />
      </a>
    </SectionContainer>
  )
}

const AboutUsPage = () => {
  return (
    <Layout page="Quem somos">
      <Banner />
      <Slogan>
        Conheça a <b>DR_</b>
      </Slogan>
      <GetToKnowUs />
      <HighLight />
      <VideoPlayer videoSource={drInstitucional} withoutStyle drInstitucional />
      <Leaders />
      <Clients />
      <CTA />
    </Layout>
  )
}

export const Head = () => <Seo title="Quem somos" />

export default AboutUsPage
